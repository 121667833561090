import React from 'react'
import Layout from '../../components/containers/layout'
import Seo from "../../components/seo/seo"
import Navbar from '../../components/nav/navbar'
import Footer from '../../components/nav/footer'
import Hr from '../../components/hr/hr.js'
import Ophthalmology from '../../components/containers/services/okulistyka.js'
import { getSrc } from "gatsby-plugin-image"

const seoImage = getSrc("../../static/oferta/okulistyka.jpg")

const Okulistyka = () => (
  <Layout>
    <Seo title="Weterynarz - okulistyka"
         description="Diagnostyka chorób w obrębie gałki ocznej oraz powiek"
         image={ seoImage }
    />
    <Navbar />
    <Hr nazwa={ "Okulistyka" }/>
    <Ophthalmology />
    <Footer />
  </Layout>
)
export default Okulistyka;
