import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.css'
import { StaticImage } from "gatsby-plugin-image"

const path = "../../../../static/oferta/"

const Ophthalmology = () => (
  <Container className="text_container">
  <StaticImage variant="top" src={path + "okulistyka.jpg"} alt="Okulistyka" height={400}/>
    <div className="divider"></div>
    <p>
    Badanie okulistyczne wykonuje lek. wet. Katarzyna Serafin
    Na wizycie okulistycznej jest wykonywana diagnostyka chorób w obrębie gałki ocznej oraz powiek.
    Posiadamy specjalistyczny sprzęt dzięki któremu jesteśmy w stanie zbadać ciśnienie wewnątrzgałkowe.
    </p>
  </Container>
);

export default Ophthalmology;
